import React from "react"

import Panel2CSS from "./panel2.module.scss"
import logo from "../../images/chalete_clean.svg"
import ButtonMore from "../button-more"

export default () => (
  <div className={Panel2CSS.div}>
    <div className={Panel2CSS.txtdiv}>
      <h4 className={Panel2CSS.title}>SIA CHALETE piedāvā</h4>
      <p className={Panel2CSS.text}>
      Dažādus materiālus koksnes aizsardzībai - apdarei interjeram un eksterjeram. Tās ir dažādas lazūras uz ūdens un šķīdinātāju bāzes, krāsas un virskārtu apdares materiāli koksnes aizsardzībai. Dabīgas eļļas mēbelēm, mēbeļu virsmām, grīdām, trepēm, durvīm, terasēm, žogiem un dārza mēbelēm. Kvalitatīvu 2 komponentu līmi parketam, kā arī 2 komponentu špakteli koksnei.
        <ButtonMore />
      </p>
    </div>
    <div className={Panel2CSS.bgdiv}>
      <img src={logo} />
    </div>
  </div>
)
