import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import "./img-boxes.scss"

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      box1: file(relativePath: {eq: "amonn-color-sakums.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      box2: file(relativePath: {eq: "wood.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      box3: file(relativePath: {eq: "amonn-color-papildinosie.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 840) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return(
    <div className="box-div">
      <div className="img-box">
        <Img fluid={data.box1.childImageSharp.fluid} className="img-box-img" />
        <h4 className="img-box-text">AMONN Color produkti</h4>
        <div className="img-box-arrow-container">
          <Link to="kategorijas/category-lignex" className="img-box-arrow-text">Skatīt</Link>
          <p className="fa fa-angle-right img-box-arrow-large fa-5x"></p>
          <p className="fa fa-chevron-right img-box-arrow-small fa-1x"></p>
        </div>
      </div>
      <div className="img-box">
        <Img fluid={data.box2.childImageSharp.fluid} className="img-box-img" />
        <h4 className="img-box-text">OLI Lacke produkti</h4>
        <div className="img-box-arrow-container">
          <Link to="kategorijas/category-olilacke" className="img-box-arrow-text">Skatīt</Link>
          <p className="fa fa-angle-right img-box-arrow-large fa-5x"></p>
          <p className="fa fa-chevron-right img-box-arrow-small fa-1x"></p>
        </div>
      </div>
      <div className="img-box">
        <Img fluid={data.box3.childImageSharp.fluid} className="img-box-img" />
        <h4 className="img-box-text">Papildinošie produkti</h4>
        <div className="img-box-arrow-container">
          <Link to="katalogs/catalogue-lignex-papildinosie" className="img-box-arrow-text">Skatīt</Link>
          <p className="fa fa-angle-right img-box-arrow-large fa-5x"></p>
          <p className="fa fa-chevron-right img-box-arrow-small fa-1x"></p>
        </div>
      </div>
    </div>
  )
}
