import React from "react"
import { Link } from "gatsby"

import "./button-more.scss"

export default () => (
  <div>
    <Link to="/par-uznemumu" className="button-more f5 link">Uzzināt vairāk <p className="fa fa-angle-right button-more-icon"></p></Link>
  </div>
)
