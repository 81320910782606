import React from "react"

import "./button-down.scss"

export default () => {

  function scrollDown(){
    window.scrollTo(0,950);
  }

  return(
    <div className="button-down" onClick={scrollDown}>
      <p className="button-arrow">&#8595;</p>
    </div>
  )

}
