import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Panel1CSS from "./panel1.module.scss"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      panel1bg: file(relativePath: { eq: "landing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data.panel1bg.childImageSharp.fluid} className={Panel1CSS.bgImg} />
  )
}

export default Image
