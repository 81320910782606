import React from "react"

import ImgBoxes from "../img-boxes"
import ButtonAllProducts from "../button-all-products"

import Panel3CSS from "./panel3.module.scss"

export default () => (
  <div className={Panel3CSS.div}>
    <ImgBoxes />
    <ButtonAllProducts />
  </div>
)
