import React from "react"
import { Link } from "gatsby"

import "./button-all-products.scss"

export default () => (
  <Link to="produkti" className="button-all link dim pointer">
    <h5 className="text-button-all">Visi produkti</h5>
  </Link>
)
