import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Panel1 from "../components/panels/panel1"
import Panel2 from "../components/panels/panel2"
import Panel3 from "../components/panels/panel3"
import Panel4 from "../components/panels/panel4"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Panel1 />
    <Panel2 />
    <Panel3 />
    <Panel4 />
  </Layout>
)

export default IndexPage
