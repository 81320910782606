import React from "react"

import Panel1CSS from "./panel1.module.scss";

import PanelBG from "./panel1-bg"
import ButtonMore from "../button-more"
import ButtonDown from "../button-down"

export default props => (
  <div className={Panel1CSS.div}>
    <PanelBG />
    <h3 className={Panel1CSS.title}>
      Kvalitatīvi apdares materiāli interjeram - eksterjeram
      <ButtonMore />
    </h3>
    <ButtonDown />
  </div>
)
